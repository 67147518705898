export const URI = 'https://kiemdien.herokuapp.com/api/admin';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEARN_ERRORS = 'CLEARN_ERRORS';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CITYS = 'GET_CITYS';

export const GET_USERS = 'GET_USERS';
export const DELETE_USERS = 'DELETE_USERS';

export const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
export const REMOVE_PICTURE = 'REMOVE_PICTURE';

export const UPDATE_INFO = 'UPDATE_INFO';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
